// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-american-legion-js": () => import("./../../../src/pages/AmericanLegion.js" /* webpackChunkName: "component---src-pages-american-legion-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-erie-pa-js": () => import("./../../../src/pages/EriePA.js" /* webpackChunkName: "component---src-pages-erie-pa-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

